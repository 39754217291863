import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class NotifyService {

  private dataForParentComponent = new BehaviorSubject<any>({});
  dataParent = this.dataForParentComponent.asObservable();

  private dataForChildrenComponent = new BehaviorSubject<any>({});
  dataChildren = this.dataForChildrenComponent.asObservable();

  private msgOnlineForChildrenComponent = new BehaviorSubject<any>({});
  onLineChildren = this.msgOnlineForChildrenComponent.asObservable();

  private dataForSameLevel = new BehaviorSubject<any>({});
  dataSameLevel = this.dataForSameLevel.asObservable();

  constructor() { }

  /**
  * Actualizar data en componente 
  * principal app.components.ts
  */
  updatedDataForParentComponent(data: any) {
    this.dataForParentComponent.next(data);
  }


  /**
  * Actualizar data en componente hijos 
  * (todos los demas componentes bajo app.components.ts)
  */
  updatedDataForChildrenComponent(data: any) {
    this.dataForChildrenComponent.next(data);
  }


  /**
   * Envía información de estado offline u online a los componente hijos 
   * (todos los demas componentes bajo app.components.ts)
   */
  sendStatusOnLineForChildrenComponent(data: any) {
    this.msgOnlineForChildrenComponent.next(data);
  }


  /**
  * Actualizar data en mismo nivel de componentes
  */
  updatedDataForSameLevel(data: any) {
    this.dataForSameLevel.next(data);
  }

}